import * as React from "react"

import {Link} from 'gatsby'
import Layout from '../../../components/layout/layout'
import Adsense from "../../../components/adsense/adsense";
const pageTitle = "RST"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto" />

                <div className="row">
                    <div className="col-12">

                            <h3 className="pt-4 pb-4">
                                <a href="">VR2WOA QSL Card</a>
                            </h3>
                            <hr />

                                <p className="text-muted">Jun 24, 2014</p>


                                <div className="info-board info-board-blue">
                                    <h4>Nice to meet you!</h4>
                                    <p>If we have made a contact over-the-air, I will be happy to send you a QSL card
                                        along with return postage for your QSL card.</p>
                                </div>

                                <div className="info-board info-board-red">
                                    <h4>你好!</h4>
                                    <p>如果我們在大氣電波成功聯繫的話，我會好樂意郵寄 QSL 卡給你，連回郵資。</p>
                                </div>

                                <img
                                    src="https://s3-ap-northeast-1.amazonaws.com/agilehobby/flower/album/2014/06/24/qsl.jpg"
                                    className="img-responsive" />


                        </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
